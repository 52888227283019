import React, { useState } from "react"
import { Link } from "react-router-dom"
import { FaTimes } from "react-icons/fa"
import whatsApp from "../images/Live_chat.png"
import '../componentsStyle/WhatsApp.css'

const WhatsApp = () => {
    const [showWhatsApp, setShowWhatsApp] = useState(false)

    const handleShow = () => {
        setShowWhatsApp(!showWhatsApp)
    }

    return (
        <div className="whatsapp">
            <div className="whatsapp-inner">
                {showWhatsApp ? (<div className="whatsapp-text">
                    <div className="whatsapp-header">
                        <h2>Hello!</h2>
                        <FaTimes className="whatsapp-times" onClick={handleShow} />
                    </div>
                    <div className="whatsapp-question">
                        <p>How can we be of service?</p>
                    </div>
                    <div className="whatsapp-btn-div">
                        <Link to="https://wa.me/2348148741927" className="whatsapp-btn" target="_blank" onClick={handleShow}>Start chat</Link>
                        {/* <button className="whatsapp-btn">Start chat</button> */}
                    </div>
                </div>) : ""}
                <img src={whatsApp} alt="whatsapp" className="whatsapp-image" onClick={handleShow} />
            </div>
        </div>
    )
}

export default WhatsApp