import "../componentsStyle/Hero.css"
import vid from "../videos/intro.mp4"
import { Link } from "react-router-dom"

const Hero = () => {
    return (
        <div className="hero">
            <div className="video-div">
                <video autoPlay muted loop className="vid">
                    <source src={vid} type="video/mp4" />
                </video>
            </div>
            <div className="hero-text">
                <h1>Got Packages To Deliver?</h1>
                <Link to="contact" className="hero-link">Contact Us</Link>
            </div>
        </div>
    )
}

export default Hero