import '../componentsStyle/Footer.css'
import { Link } from 'react-router-dom'
import { FaMobileAlt, FaEnvelope, FaFacebook, FaInstagram, FaTiktok } from "react-icons/fa"
import { FaLocationDot } from "react-icons/fa6"

const Footer = () => {
    return (
        <div className='footer'>
            <div className='footer-address'>
                <h2>Locate Us</h2>
                <div className='footer-address-content'>
                    <span className='footer-address-content-icon'>
                        <FaLocationDot />
                    </span>
                    <span className='footer-address-content-address'>
                        <address>
                            Plot 16 Lateef Jakande Road, <br /> Agidingbi, Ikeja, Lagos.
                        </address>
                    </span>
                </div>
            </div>
            <div className='footer-contact'>
                <h2>Contact Us</h2>
                <div className='footer-contact-content'>
                    <span className='footer-contact-icon'>
                        <FaMobileAlt />
                    </span>
                    <span className='footer-contact-text'>
                        +234 802 261 8643 <br /> +234 814 874 1927
                    </span>
                </div>
                <br />
                <div className='footer-contact-content'>
                    <span className='footer-contact-icon'>
                        <FaEnvelope />
                    </span>
                    <span className='footer-contact-text'>info@emoil.com.ng</span>
                </div>
            </div>
            <div className='footer-social'>
                <h2>Follow Us</h2>
                <div className='footer-social-icons'>
                    <Link to='https://www.facebook.com/profile.php?id=100067576595467&mibextid=LQQJ4d' target='_blank' className='footer-social-link'><FaFacebook /></Link>
                    <Link to='https://www.instagram.com/emoil_logistics_?igsh=MTlkcTIxZzl3OGpmMA%3D%3D&utm_source=qr' target='_blank' className='footer-social-link'><FaInstagram /></Link>
                    <Link to='https://www.tiktok.com/@emoil.logistics?_t=8krqvA09CsA&_r=1' target='_blank' className='footer-social-link'><FaTiktok /></Link>
                </div>
            </div>
            <div className='footer-copyright'>
                <Link to='/' className='footer-copyright-link'>Terms & Condition</Link>
                <Link to='/' className='footer-copyright-link'>Privacy Policy</Link>
                <p>&copy; 2024 EMOIL Logistics</p>
            </div>
        </div>
    )
}

export default Footer