import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import '../componentsStyle/OurClients.css'
import Slider from "react-slick"
import client1 from "../images/client1.jpg"
import client2 from "../images/client2.jpg"
import client3 from "../images/client3.jpg"
import client4 from "../images/client4.jpg"
import client5 from "../images/client5.jpg"

const OurClients = () => {
    let settings = {
        // dots: true,
        infinite: true,
        autoplay: true,
        speed: 10000,
        autoplaySpeed: 0,
        slidesToShow: 1,
        slidesToScroll: 1,
        cssEase: "linear",
        pauseOnHover: true,
        className: "center",
    }

    return (
        <div className="our-client">
            <h1>Our Partners and Clientele</h1>
            <Slider {...settings} className="our-client-slider">
                <div className="our-client-item">
                    <img src={client1} alt="client1" className="our-client-img" />
                </div>
                <div className="our-client-item">
                    <img src={client2} alt="client2" className="our-client-img" />
                </div>
                <div className="our-client-item">
                    <img src={client3} alt="client3" className="our-client-img" />
                </div>
                <div className="our-client-item">
                    <img src={client4} alt="client4" className="our-client-img" />
                </div>
                <div className="our-client-item">
                    <img src={client5} alt="client5" className="our-client-img" />
                </div>
            </Slider>
        </div>
    )
}

export default OurClients