import React, { useLayoutEffect } from "react";
import './Services.css'
// import { motion } from 'framer-motion'
import finance from '../images/emoil-finance.jpg'
import warehouse from '../images/emoil-warehouse.jpg'
import fasttrack from '../images/emoil-fasttract.jpg'

const Services = () => {
    useLayoutEffect(() => {
        window.scrollTo(0,0)
      })
      
    return (
        <div className='service'>
            <h1>Our Range of Services</h1>

            {/* service 0 */}
            <div className='service-group'>
                {/* <h2>Import Financing</h2>
                <hr /> */}
                <p>EMOIL is a company specializing in the provision of a wide range of international trade services such as clearing of imported items and fast track delivery, warehousing, the company also provide such services as export freight forwarding, documentations and trucking.</p>
                <p><b>IMPORT:</b> Form M processing, PAAR Processing and follow-ups, clearing (Fast track and port physical examination) and logistics services.</p>
                <p><b>EXPORT:</b> NXP form processing, export booking, export documentations, freight forwarding and logistics services.</p>
                <p>We promise to conduct our business with utmost respect for the industry we serve and to work in a manner that meets the needs of the present without compromising the future.</p>
            </div>

            {/* service 1 */}
            <div className='service-group'>
                <h2>Import Financing</h2>
                <hr />
                <div className='service-inner-group'>
                    <div className='service-img-div img-1'>
                        <img src={finance} alt='finance' className='service-img' />
                    </div>
                    <div className='service-text text-1'>
                        <p>At Emoil Logistics we are consistent and proactive in the business of supply chain services. We have raised our bar to the level of financing some parts of the clearing process as may be required by our clients or the full clearing financing taken care of, ranging from duty payment, shipping and terminal charges payment and haulage financing as may be required by our clients.</p>
                        <p>We also assist with financing the importation of your goods into Nigeria with the following benefits:</p>
                        <p>Get improved cash flow through working capital. You can enjoy off-balance-sheet financing as we only invoices once the goods have been delivered with competitive rate through our banking partners. We offer complete financial solution.</p>
                    </div>
                </div>
            </div>

            {/* service 2 */}
            <div className='service-group'>
                <h2>Warehousing</h2>
                <hr />
                <div className='service-inner-group'>
                    <div className='service-text text-2'>
                        <p>We deliver all consignments promptly to our clients' warehouses and there are times when, due to space constraints or other reasons, our Clients are unable to accept the goods onto their premises.</p>
                        <p>In these instances we are able to offer warehousing service that stores the goods on behalf of our clients until the Clients requires us to deliver the goods.</p>
                    </div>
                    <div className='service-img-div img-2'>
                        <img src={warehouse} alt='finance' className='service-img' />
                    </div>
                </div>
            </div>

            {/* service 3 */}
            <div className='service-group'>
                <h2>Fast Track Services and Port Physical Examination</h2>
                <hr />
                <div className='service-inner-group'>
                    <div className='service-img-div img-3'>
                        <img src={fasttrack} alt='finance' className='service-img' />
                    </div>
                    <div className='service-text text-3'>
                        <p>Our turnaround time for fast track delivery is 3days from the date of consignment discharge from the vessel and empty container will be returned to the terminal within 4days from the date of offloading in our client's warehouse.</p>
                        <p>Our turnaround time for port physical examination delivery is 7days from the date of consignment discharge from the vessel and empty container will be returned to the terminal within 4days from the date of offloading in our client's warehouse.</p>
                        <p>Our relationship with trusted haulage companies will enable us achieve all we have promised.</p>
                    </div>
                </div>
            </div>

            {/* service 4 */}
            {/* <div className='service-group'>
                <h2>Import Financing</h2>
                <hr />
                <div className='service-inner-group'>
                    <div className='service-text text-4'>
                        <p>At Emoil Logistics we are consistent and proactive in the business of supply chain services. We have raised our bar to the level of financing some parts of the clearing process as may be required by our clients or the full clearing financing taken care of, ranging from duty payment, shipping and terminal charges payment and haulage financing as may be required by our clients.</p>
                        <p>We also assist with financing the importation of your goods into Nigeria with the following benefits:</p>
                        <p>Get improved cash flow through working capital. You can enjoy off-balance-sheet financing as we only invoices once the goods have been delivered with competitive rate through our banking partners. We offer complete financial solution.</p>
                    </div>
                    <div className='service-img-div img-4'>
                        <img src={finance} alt='finance' className='service-img' />
                    </div>
                </div>
            </div> */}
        </div>
    )
}

export default Services