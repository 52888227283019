import React, { useState } from "react"
import { FaTimes } from "react-icons/fa"
// import { motion } from "framer-motion"
import "../componentsStyle/ContactForm.css"

const ContactForm = () => {
    const initialMail = {
        name: "",
        email: "",
        subject: "",
        message: ""
    }

    const [mailMessage, setMailMessage] = useState(initialMail)
    const [mailResponse, setMailResponse] = useState(undefined)
    const [isSending, setIsSending] = useState(false)

    const handleChange = e => {
        setMailMessage({
            ...mailMessage,
            [e.target.name]: e.target.value
        })
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        setIsSending(true)
        try {
            let res = await fetch("https://sendemail.emoil.com.ng/send", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(mailMessage)
            })
            let data = res.json()
            console.log(data)

            if (data) {
                setMailResponse(true)
                setMailMessage(initialMail)
            } else {
                setMailResponse(false)
            }
        } catch(error) {
            console.log(error)
        } finally {
            setIsSending(false)
            setTimeout(() => setMailResponse(undefined), 10000)
        }
    }

    return (
        <div 
            className="contact-form" 
            id="form"
            
        >
            <h1>Do you have any enquiry?</h1>
            <p>Kindly fill out the form below. We will get back to you asap.</p>
            <form action="send" method="post" onSubmit={handleSubmit}> 
                <div className="input-item">
                    <input 
                        type="text" 
                        id="name" 
                        name="name" 
                        placeholder="Your name" 
                        required 
                        value={mailMessage.name}
                        onChange={handleChange}
                    />
                </div>
                {/* <div className="input-item">
                    <input type="tel" id="phone" name="phone" placeholder="Phone Number" />
                </div> */}
                <div className="input-item">
                    <input 
                        type="email" 
                        id="email" 
                        name="email" 
                        placeholder="Email" 
                        required 
                        value={mailMessage.email}
                        onChange={handleChange}
                    />
                </div>
                <div className="input-item">
                    <input 
                        type="text" 
                        id="subject" 
                        name="subject" 
                        placeholder="Subject" 
                        required 
                        value={mailMessage.subject}
                        onChange={handleChange}
                    />
                </div>
                <div className="input-item">
                    <textarea 
                        rows="5" 
                        placeholder="Your Message"
                        required
                        name="message"
                        id="message"
                        value={mailMessage.message}
                        onChange={handleChange}
                    ></textarea>
                </div>
                <input 
                    type="submit" 
                    value={isSending ? "Sending..." : "Send"} 
                    // disabled
                    className="submit" 
                />
                <div className="mail-response">
                    {mailResponse === undefined ? "" : mailResponse === true ? (
                        <span className="success-msg">
                            <p>Thank you for your message! We will revert shortly.</p>
                            <FaTimes onClick={() => setMailResponse(undefined)} className="x" />
                        </span>
                    ) : (
                        <span className="fail-msg">
                            <p>Message not sent! Please contact us through other means.</p>
                            <FaTimes onClick={() => setMailResponse(undefined)} className="x" />
                        </span>
                    )}
                </div>
            </form>
        </div>
    )
}

export default ContactForm