import React, { useLayoutEffect } from "react";
import Hero from '../components/Hero'
import OurClients from '../components/OurClients'
import OurOffer from '../components/OurOffer'
import Testimonial from '../components/Testimonial'
import './Home.css'

const Home = () => {
    useLayoutEffect(() => {
        window.scrollTo(0,0)
      })

    return (
        <div className='home'>
            <Hero />
            <OurOffer />
            <OurClients />
            <Testimonial />
        </div>
    )
}

export default Home