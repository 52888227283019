import './ErrorPage.css'

const ErrorPage = () => {
    return (
        <div className='errorPage'>
            <h1>Error Page</h1>
        </div>
    )
}

export default ErrorPage