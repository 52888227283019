import '../componentsStyle/ContactAddress.css'
import { FaMobileAlt, FaEnvelope } from "react-icons/fa"
import { FaLocationDot } from "react-icons/fa6"
// import { motion } from 'framer-motion'

const ContactAddress = () => {
    return (
        <div 
            className='contact-address'
           
        >
            <h1>Contact Us</h1>
            <span className='contact-address-line'>
                <FaLocationDot />
                <address>
                    Plot 16 Lateef Jakande Road, <br /> Agidingbi, Ikeja, Lagos.
                </address>
            </span>
            <span className='contact-address-line'>
                <FaMobileAlt />
                <span>
                    +234 802 261 8643 <br /> +234 814 874 1927
                </span>
            </span>
            <span className='contact-address-line'>
                <FaEnvelope />
                <span>info@emoil.com.ng</span>
            </span>
        </div>
    )
}

export default ContactAddress