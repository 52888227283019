import { Outlet } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import WhatsApp from "./components/WhatsApp";

const App = () => {
  return (
    <div className="App">
      <Header />
      <Outlet />
      <WhatsApp />
      <Footer />
    </div>
  );
}

export default App;
