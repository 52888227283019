import React, { useLayoutEffect } from "react";
import './About.css'
import { FaCircleCheck, FaCheck } from "react-icons/fa6"
import profileImg from '../images/emoil-culture.jpg'

const About = () => {
    useLayoutEffect(() => {
        window.scrollTo(0,0)
      })

    return (
        <div className='about'>
            <h1 className='about-h1'>About Us</h1>
            <div className='company-profile'>
                <div className='company-profile-img'>
                    <img src={profileImg} alt='company profile pic' />
                </div>
                <div className='company-profile-text'>
                    <h1>Company Profile</h1>
                    <p>
                        EMOIL Logistics, a Federal Government Licensed import and export Solutions Company 
                        that specializes on sourcing, importation and exportation of crude materials. The need to create a 
                        reliable import and export solution has made the company set for itself the task of providing a 
                        complete import and export solutions to our clients across the world.
                    </p>
                    <p>
                        EMOIL Logistics is a well-established firm with a cordial relationship with several 
                        government bodies and authorities. The company's presence is maintained through our office in Apapa, Lagos.
                    </p>
                    <p>
                        Our reputation is founded on our ability to deliver an excellent service comparable in standard to that 
                        obtained in the western world. We are available for any follow up discussion or enquiries, as we look 
                        forward to an opportunity to demonstrate to you our professional expertise, competence and time bound 
                        commitment.
                    </p>
                </div>
            </div>
            <div className='about-values'>
                <div className='about-item'>
                    <h2>Our Mission</h2>
                    <p>To maximize our client's satisfaction by providing time bound services to meet their various business requirement through our highly motivated and committed employees.</p>
                </div>
                <div className='about-item'>
                    <h2>Our Vision</h2>
                    <p>To be the most innovative international trade and logistics services provider across the world.</p>
                </div>
                <div className='about-item'>
                    <h2>Our Core Values</h2>
                    <div className='values-list'>
                        <span className='our-value-list'> <FaCircleCheck className='list-icon' /> &nbsp; Resilience</span>
                        <span className='our-value-list'> <FaCheck className='list-icon' /> &nbsp; Speed</span>
                        <span className='our-value-list'> <FaCircleCheck className='list-icon' /> &nbsp; Reliability</span>
                        <span className='our-value-list'> <FaCheck className='list-icon' /> &nbsp; Empowered Employees</span>
                        <span className='our-value-list'> <FaCircleCheck className='list-icon' /> &nbsp; Excellent Customer Relationship</span>
                    </div>
                </div>
                <div className='about-item'>
                    <h2>Our Commitments</h2>
                    <p>We promise to conduct our business with utmost diligence for the industry we serve and to work in a way that meets the needs of the present without compromising the future.</p>
                </div>
            </div>
        </div>
    )
}

export default About