import testimonial1 from "../images/testimonial1.jpg"
import testimonial2 from "../images/testimonial2.jpg"
import testimonial3 from "../images/testimonial3.jpg"
// import testimonial4 from "../images/testimonial4.jpg"

export const testimonialInfo = [
    {
        name: "Frank Adebanji",
        review: "Emoil Logistics is the real deal when it comes to delivery. I started the process by simply contacting them on their website, my package was picked up and delivered to the destination in no time.",
        pic: testimonial1
    },
    {
        name: "Naomi",
        review: "Greate customer service. The staff attended to me promptly and in a well organised manner.",
        pic: testimonial2
    },
    {
        name: "Felix Chux",
        review: "I was carried along throughout the process. The process was seamless and transparent. Thank you Emoil Logistics.",
        pic: testimonial3
    },
    // {
    //     name: "Name4",
    //     review: "This is a sample review about emoil. There services is top notch, the deliver fast.",
    //     pic: testimonial4
    // }

]