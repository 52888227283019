import React, { useState } from 'react'
import '../componentsStyle/Header.css'
import { Link } from 'react-router-dom'
import { FaBars, FaTimes } from 'react-icons/fa'
import logo from '../images/emoil1.png'

const Header = () => {
    const [barTimes, setBarTimes] = useState(true)
  
    const handleBarTimes = () => {
        const nav = document.querySelector('.nav')
        nav.classList.toggle('show')
        setBarTimes(!barTimes)
    }

    return (
        <div className='header'>
            <Link to='/' className='logo-link'>
                <img src={logo} alt='emoil logo' className='logo' />
            </Link>
            <div className="bars" onClick={handleBarTimes}>
                {barTimes ? <FaBars /> : <FaTimes />}
            </div>
            {/* <div className='nav-div'> */}
                <ul className='nav'>
                    <li className='nav-item'>
                        <Link to='/' className='nav-link' onClick={handleBarTimes}>Home</Link>
                    </li>
                    <li className='nav-item'>
                        <Link to='about' className='nav-link' onClick={handleBarTimes}>About</Link>
                    </li>
                    <li className='nav-item'>
                        <Link to='services' className='nav-link' onClick={handleBarTimes}>Services</Link>
                    </li>
                    <li className='nav-item'>
                        <Link to='contact' className='nav-link' onClick={handleBarTimes}>Contact</Link>
                    </li>
                </ul>
            {/* </div> */}
        </div>
    )
}

export default Header