import '../componentsStyle/OurOffer.css'
import offer1 from '../images/offer1.jpg'
import offer2 from '../images/offer2.jpg'
import offer3 from '../images/offer3.jpg'
// import { FcHighPriority } from "react-icons/fc"
// import { MdPriceCheck } from "react-icons/md"
// import { TfiTimer } from "react-icons/tfi"
// import { MdPriorityHigh } from "react-icons/md"
// import { MdOutlineAccessTimeFilled } from "react-icons/md"

const OurOffer = () => {
    return (
        <div className='offer'>
            <h1>Our Promise To You</h1>
            <div className='offer-group'>
                <div className='offer-item'>
                    <span className='offer-span'>
                        <img src={offer1} alt='offer1' className='offer-img' />
                    </span>
                    <h3>Priority Delivery</h3>
                    <p>Our client is our first priority! With our established reputation for speed and reliability, 
                        EMOIL offers priority delivery services to meet our clients needs daily. 
                        We understand how important it is getting that package to its destination, 
                        therefore we make it our priority.</p>
                </div>
                <div className='offer-item'>
                    <span className='offer-span'>
                        <img src={offer2} alt='offer2' className='offer-img' />
                    </span>
                    <h3>Affordable Prices</h3>
                    <p>You don't need to break the bank to get your package delivered.
                        We offer premium delivery services at affordable rates that you can't get else where.
                    </p>
                </div>
                <div className='offer-item'>
                    <span className='offer-span'>
                        <img src={offer3} alt='offer3' className='offer-img' />
                    </span>
                    <h3>Timely Delivery</h3>
                    <p>Time is of essence in business transaction. We ensure timely delivery of your packages.
                        We don't give excuses, we deliver as we promise.
                    </p>
                </div>
                {/* <div className='offer-item'>
                    <span>SVG Icon</span>
                    <h3>Priority Delivery</h3>
                    <p>We put you first! With our established reputation for speed and reliability, GIGL offers priority shipping service to meet your needs daily, every single hour. If getting that package to its destination means a lot to you, then it's important to us.</p>
                </div>
                <div className='offer-item'>
                    <span>SVG Icon</span>
                    <h3>Priority Delivery</h3>
                    <p>We put you first! With our established reputation for speed and reliability, GIGL offers priority shipping service to meet your needs daily, every single hour. If getting that package to its destination means a lot to you, then it's important to us.</p>
                </div>
                <div className='offer-item'>
                    <span>SVG Icon</span>
                    <h3>Priority Delivery</h3>
                    <p>We put you first! With our established reputation for speed and reliability, GIGL offers priority shipping service to meet your needs daily, every single hour. If getting that package to its destination means a lot to you, then it's important to us.</p>
                </div> */}
            </div>
        </div>
    )
}

export default OurOffer