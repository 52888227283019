import React, { useLayoutEffect } from "react";
import './Contact.css'
import contactImg from '../images/contact-us2.jpg'
import ContactForm from '../components/ContactForm'
import ContactAddress from '../components/ContactAddress'

const Contact = () => {
    useLayoutEffect(() => {
        window.scrollTo(0,0)
      })

    return (
        <div className='contact'>
            <div className='contact-img-div'>
                <img src={contactImg} alt='contact' className='contact-img' />
            </div>
            <div className='contact-details'>
                <ContactForm />
                <ContactAddress />
            </div>
        </div>
    )
}

export default Contact